main {
    position: relative;
}


.page-content {
    margin: 60px 0;
    &.hm {
        margin: 0;
    }
}

.pagetitle {
    .container {
        background-color: $main-color-three;
        @include media(">=md") {
            background-color: transparent;
        }
        .inner {
            padding: 10px 0;
            position: relative;
            @include media(">=md") {
                display: inline-block;
                background-image: url(../images/bg-title.svg);
                background-position: center right;
                background-size: cover;
                padding-right: 50px;
                &:before {
                   display: block;
                   content: '';
                   height: 100%;
                   width: 1000%;
                   background-color: $main-color-three;
                   position: absolute;
                   top: 0;
                   bottom: 0;
                   left: -999%;
                   z-index: 0;
               }
            }

             h1 {
                 background-color: $main-color-three;
                 display: inline-block;
                 position: relative;
                 color: $main-color-one;
                 font-size: rem(28);
                 line-height: rem(30);
                 @include media(">=md") {
                    font-size: rem(34);
                    line-height: rem(36);
                 }
             }
             span {
                 display: block;
                 img {
                     height: 10px;
                 }
             }
        }
    }
    .media {
        display: none;
    }
    &.has-image {
        .inner {
            position: relative;
            @include media(">=md") {
                position: absolute;
            }
        }
        .media {
            display: block;
        }
    }
}




figure {
    &.image {
        width: 100%;
        padding: 5px;
        background-color: $xlt-grey;
        border: 1px solid $lt-grey;
        @include media(">=xs") {
            max-width: 300px;
        }
        @include media(">=sm") {
            max-width: 400px;
        }
        img {
            width: 100%;
            max-width: 100%;
        }
        figcaption {
            padding-top: 5px;
            text-align: center;
            font-size: rem(12);
            line-height: rem(14);
        }
    }
    &.left {
        float: left;
        margin: 0 20px 20px 0;
    }
    &.right {
        float: right;
        margin: 0 0 20px 20px;
    }
}


a {
    &#scroll-top {
        opacity: 0;
        position: fixed;
        width: 20px;
        height: 40px;
        bottom: 20px;
        right: 30px;
        z-index: 999;
        color: $main-color-two;
        text-shadow: 1px 0px 5px rgba(0, 0, 0, .5);
        font-size: 40px;
        transition: all 0.5s ease;
        &:focus {
            outline: none;  
        }
        span {
            color: #FFF;
            &:hover {
                color: #000;
            }
        }
        &.visible {
            opacity: 1;
            transition: all 0.5s ease;
        }
    } 
}




.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}
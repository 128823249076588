.carousel-hero {
	.item {
        background-color: $main-color-one;
        position: relative;
        z-index: 2;
        @include media(">=sm") {
            background-color: transparent;
        }
		.inner-wrap {
			position: relative;
			overflow: hidden;
			display: block;
            z-index: 0;
            background-color: $main-color-one;
			&:before {
				display: none;
			}
			.media-wrap {
                &:before {
                    display: none;
                    @include media(">=sm") {
                        display: block;
                        content: '';
                        background-image: url(../images/hero-overlay.svg);
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-size: contain;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }    
                }
				img {
                    width: 100%;	
                }
			}
			.inner {
				position: relative;
				@include media(">=sm") {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
                    margin: 0 auto;
				}
				.message {
                    display: block;
					position: relative;
					color: $white;
					color: $white;
					padding: 20px;
					font-size: rem(14);
					line-height: rem(16);
					font-weight: 700;
                    z-index: 2;
                    // &:before {
                    //     display: block;
                    //     content: '';
                    //     background-image: url(../images/hero-overlay.svg);
                    //     // background-size: cover;
                    //     background-repeat: no-repeat;
                    //     background-position: left center;
                    //     width: 100%;
                    //     height: 100%;
                    //     position: absolute;
                    //     top: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     left: 0; 
                    //     @include media(">=sm") {
                    //         display: none;
                    //     }
                    // }
                    @include media(">=sm") {
                        background-color: transparent;
                        padding: 0 75px;
						font-size: rem(18);
                        line-height: rem(20);
                        width: 600px;
                    }
                    @include media(">=xl") {
                        padding: 0 75px 0 130px;
                    }
					.title {
                        position: relative;
                        font-family: $header-font;
                        font-size: rem(20);
                        margin-bottom: rem(15);
                        @include media(">=sm") {
                            text-shadow: 0px 0px 4px rgba($black, 0.75);
                            font-size: rem(32);
                            line-height: rem(32);
                            margin-bottom: 20px;
                        }
					}
					.detail {
                        position: relative;
                        display: block;
                        @include media(">=sm") {
                            text-shadow: 0px 0px 4px rgba($black, 0.75);
                            position: relative;
                        }
					}
					a {
						&.bttn {
                            position: relative;
                            display: inline-block;
                            bottom: 0;
                            border: 2px solid $white;
                            padding: 10px 20px;
                            color: $white;
                            font-size: rem(20);
                            font-weight: 700;
                            margin-top: 20px;
                            width: auto;
                            text-align: center;
                            background-color: transparent;
                            text-decoration: none !important;
                            text-transform: uppercase;
                            border-radius: rem(30);
							&:after {
								font-family: $icon-font;
								content: '\f054';
								margin-left: 5px;
							}
							&:hover {
								color: $main-color-one;
								background-color: $white;
                                text-decoration: none;
                                border-color: $main-color-one-alt;
                            }
                            @include media(">=sm") {
                                text-shadow: 0px 0px 4px rgba($black, 0.75);
                            }
						}
					}
				}
			}
        }
        &.no-overlay {
            .inner-wrap {
                .media-wrap {
                    &:before {
                        @include media(">=sm") {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .slick-dots {
        position: absolute;
        bottom: -55px;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    &.slick-dotted.slick-slider {
        margin-bottom: 50px;
    }
}

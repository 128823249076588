.slab-container {  
    max-width: $container-max-width;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    .slab-row {
        @include media(">=md") {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
        }
    }
    .half {
        width: 100%;
        @include media(">=sm") {
            padding-right: 15px;
            padding-left: 15px;
        }
        @include media(">=lg") {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.carousel-container {
    max-width: $container-max-width;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    .carousel-row {
        @include media(">=md") {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
        }
    }

}

.footer-container {
    width: rem(1400);
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    .footer-row {
        @include media(">=md") {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
        }
    }

}

.container {
    max-width: none;
    @include media(">=sm") {
        max-width: 100%;
    }
    @include media(">=md") {
        max-width: rem(960);
    }
    @include media(">=lg") {
        max-width: rem(1400);
    }
    @include media(">=xl") {
        max-width: rem(1400);
    }
}

.hm-events {
    .featured-wrap {
        padding-bottom: 40px;
        height: 100%;
    }
    .featured {
        position: relative;
        min-height: 100px;
        background-color: $main-color-two;
        padding: 40px;
        margin-bottom: rem(100);
        color: $white;
        height: 100%;
        @include media(">=md") {
            margin-left: 40px;
            display: flex;
            margin-bottom: 70px;
        }
        &:after {
            display: block;
            content: '';
            background-color: $hilite-color-two;
            width: 60%;
            height: 80%;
            position: absolute;
            z-index: -1;
            margin-left: -80px;
            bottom: -40px;
        }
        .title {
            min-width: 50%;
            font-size: rem(36);
            line-height: rem(38);
            font-weight: 700;
            margin-bottom: 15px;
            .date-time {
                font-size: rem(18);
            }
        }
        .details {
            position: relative;
            @include media(">=md") {
                padding-left: 20px;
                font-size: rem(20);
            }
            a {
                display: block;
                position: absolute;
                bottom: -80px;
                right: 20px;
                border: 2px solid transparent;
                padding: 20px;
                color: $white;
                font-size: rem(20);
                font-weight: 700;
                width: auto;
                text-align: center;
                background-color: $main-color-one-alt;
                text-decoration: none!important;
                text-transform: uppercase;
                &:after {
                    font-family: $icon-font;
                    content: '\f054';
                    margin-left: 5px;
                }
                &:hover {
                    color: $main-color-one-alt;
                    background-color: $white;
                    text-decoration: none;
                    border-color: $main-color-one-alt;
                }
            }
        }
    }
    .hm-event-list {
        margin: 0;
        // margin-left: 20px;
        // margin-right: 20px;
        padding: 0;
        list-style-type: none;
        @include media(">=lg") {
            margin-right: 0;
        }
        li {
            margin-bottom: 20px;
            a {
                display: flex;
                width: 100%;
                .date {
                    display: block;
                    border: 3px solid $main-color-one;
                    min-width: 100px;
                    min-height: 100px;
                    padding: 10px;
                    text-align: center;
                    color: $main-color-two;
                    font-size: rem(32);
                    line-height: rem(32);
                    font-weight: 700;   
                    span {
                        display: block;
                        text-align: center;
                        color: $main-color-two;
                        font-size: rem(68);
                        line-height: rem(20);
                        font-weight: 700;  
                    }
                }
                .details {
                    display: block;
                    padding-left: 20px;
                    .title {
                        display: block;
                        text-transform: uppercase;
                        font-size: rem(20);
                        line-height: rem(22);
                        font-weight: 700;
                        color: $main-color-two;
                        margin-bottom: 10px;
                        @include multiLineEllipsis($lineHeight: 1.25rem, $lineCount: 2, $bgColor: $white);  
                    }
                    .description {
                        display: block;
                        font-weight: 700;
                        @include multiLineEllipsis($lineHeight: 1.25rem, $lineCount: 2, $bgColor: $white);  
    
                    }
                    .time {
                        display: block;
                        font-style: italic;
                        font-weight: 700;
                    }
                }
                &:hover {
                    text-decoration: none;
                    .date {
                        text-decoration: none;
                        background-color: $main-color-one-alt;
                        color: $white;
                        span {
                            color: $white;
                        }
                    }
                    .details {
                        .title {
                            color: $main-color-one-alt;
                        }
                    }
                }
            }
        }  
    }
    .all-events {
        border-top: 1px dotted $main-color-one;
        margin-top: 20px;
        padding-top: 20px;
        text-align: center;
        a {
            display: inline-block;
            border: 2px solid transparent;
            padding: 20px;
            color: $white;
            font-size: rem(20);
            font-weight: 700;
            width: auto;
            text-align: center;
            background-color: $main-color-one-alt;
            text-decoration: none!important;
            text-transform: uppercase;
            &:after {
                font-family: $icon-font;
                content: '\f054';
                margin-left: 5px;
            }
            &:hover {
                color: $main-color-one-alt;
                background-color: $white;
                text-decoration: none;
                border-color: $main-color-one-alt;
            }
        }
    }
}
// PROJECT COLORS

// Brand Colors
$main-color-one: #b30000;
$main-color-one-alt: #b30000;
$main-color-two: #013353;
$main-color-three: #ffb500;
// $main-color-two: #0c2340;



$hilite-color-one: #ffb400;
$hilite-color-two: #eaf0f5;


// Greyscale colors
$white: #fff;
$black: #000;
$rgb-white: "255,255,255" !default;
$rgb-black: "0,0,0" !default;
$xlt-grey: #eeeeee;
$lt-grey: #cfcfcf;
$grey: #90978f;
$dk-grey: #3e3f40;

$main-color-five: blue;
$hilite-color: yellow;


$text-color: $black;

$focus-color: yellow;


$nav-color-one: $main-color-one;
$nav-color-hover-one: rgba(0,0,0,.5);
$nav-color-two: $main-color-two;
$nav-color-three: purple;
$nav-color-four: green;

// Standard Nav Colors
// $nav-color-one: $main-color-one;
// $nav-color-two: $main-color-two;
// $nav-color-hover-one: rgba(0,0,0,.5);
// $nav-color-three: purple;
// $nav-color-four: green;



// Link & button colors
$color-link: $main-color-one;
$color-link-hover: $main-color-two;
$color-link-alt: $main-color-two;
$color-link-alt-hover: lighten($main-color-two, 10%);
$color-button: $main-color-two;
$color-button-hover: darken($main-color-two, 20%);
$color-button-alt: $main-color-two;
$color-button-alt-hover: lighten($main-color-two, 20%);

// Sidebar nav colors
$sidebar-nav-color-one: $main-color-two;
$sidebar-nav-color-two: $main-color-one;
// Alert colors
$color-alert: #003c71;
$color-alert-emergency: #ea002b;



$xdk-grey: #181818;

/* Fonts */
$primary-font: 'ff-meta-web-pro',sans-serif !important;
$nav-font: 'ff-meta-web-pro',sans-serif !important;
$header-font: 'pt serif', serif !default;
$condensed-font: 'adelle-condensed', serif !default;
$condensed-serif-font: 'adelle-condensed', serif !default;
$serif-font: 'adelle', serif !default;
$sans-serif-font: 'ff-meta-web-pro', sans-serif !important;
// $icon-font: 'FontAwesome';
$icon-font: 'Font Awesome 5 Free';




// $primary-font: 'ff-meta-web-pro', sans-serif !important;
// $nav-font: 'ff-meta-web-pro',sans-serif !important;
// $header-font:  'adelle', serif !default;
// $nav-font:  'ff-meta-web-pro', sans-serif !important;
// $condensed-font: 'ff-meta-web-pro-condensed', sans-serif;
// $condensed-serif-font: 'adelle-condensed', serif !default;
// $serif-font: 'adelle', serif !default;
// $sans-serif-font: 'ff-meta-web-pro', sans-serif !important;




// Slick Slider Inputs
$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Free" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $white !default;
$slick-dot-color: #376087 !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f137" !default;
$slick-next-character: "\f138" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-character-active: "\f111" !default;
$slick-dot-size: 22px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


$slick-foo: 1 !default;





$container-max-width: 87.5rem;




// Top Nav Colors
$top-nav-color-one: $main-color-two;
$top-nav-border-color-one: $main-color-two;
$top-nav-border-color-one-hover: $white;
$top-nav-border-color-mob: $white;

// First Level Subnav Colors
$subnav-border-color: #777;
$subnav-color: #777;
$subnav-bg-color: $white;
$subnav-color-hover: $white;
$subnav-bg-color-hover: #777;

// Second Level Subnav Colors
$subnav-color2: $white;
$subnav-bg-color2: #424242;
$subnav-color-hover2: $white;
$subnav-bg-color-hover2: $white;












// // Transitions
// $transition-time: .2s;
// $transition-easing: ease-out;

// // Margins
// $margin-bottom: rem(40);

// // Widths
// $max-width: rem(1400);




// // Nav Colors
// $primary-button-color:$white;
// $primary-button-color-hover: #CCC;
// $nav-primary: $main-color-one;
// $nav-bg: $white;
// $breadcrumb-color: #CCC;
// $breadcrumb-bg: #ac1f27;

// // Mega Menu Main Nav Colors
// $mega-bg-one: darken($main-color-one, 15%);
// $mega-bg-two: green;
// $mega-link-bg-hover: rgba(0,0,0,.5);
// $mega-link-color: rgba(255,255,255,.95);
// $mega-link-color-hover: $white;
// $mega-link-border-color: rgba(255,255,255,.75);
// $mega-link-border-color-hover: $white;

// // Mega Menu Mobile Nav Colors
// $mega-mob-color-one: $main-color-one;
// $mega-mob-color-two: darken($main-color-one, 5%);
// $mega-mob-link-color-one: $white;
// $mega-mob-border-color: $white;

// // Top Nav Colors
// $top-nav-color-one: $main-color-two;
// $top-nav-border-color-one: $main-color-two;
// $top-nav-border-color-one-hover: $white;
// $top-nav-border-color-mob: $white;

// // First Level Subnav Colors
// $subnav-border-color: #777;
// $subnav-color: #777;
// $subnav-bg-color: $white;
// $subnav-color-hover: $white;
// $subnav-bg-color-hover: #777;

// // Second Level Subnav Colors
// $subnav-color2: $white;
// $subnav-bg-color2: #424242;
// $subnav-color-hover2: $white;
// $subnav-bg-color-hover2: $white;

// // Toggle Sub
// $toggle-sub-color-one: $mega-mob-color-two;
// $toggle-sub-height: 69px;
// $toggle-sub-width: 68px;




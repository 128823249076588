.breadcrumb-wrapper {
    padding: 10px 0;
    ul {
        &.breadcrumbs {
            margin: 0;
            list-style: none;
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            display: block;
            text-align: left;
            margin-top: -5px;
            position: relative;
            z-index: 100;
            li {
                display: inline-block;
                margin: 0;
                padding: 0;
                a, a:visited {
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 12px;
                    line-height: 12px;
                    color: $main-color-one;
                    padding: 0;
                    font-weight: 700;
                    &:hover, &:active {
                        color:$xdk-grey;
                        text-decoration: underline;
                    }
                }
            }
            >li+li:before {
                padding: 0 5px 0 0;
                font-family: $icon-font;
                font-size: 10px;
                font-weight: 300;
                color: $main-color-one;
                content: " \f061 ";
            }
        }
    }
}


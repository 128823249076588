.link-grid {
    color: $main-color-two;
    padding-right: 20px;
    padding-left: 20px;
	.title {
		display: block;
		font-size: rem(36);
		margin-bottom: rem(10);
        font-weight: 700;
        position: relative;
        text-align: center;
	}
	.subtitle {
		display: block;
		font-size: rem(20);
        margin-bottom: rem(20);
        position: relative;
        text-align: center;
        margin-bottom: 40px;
    }
	ul {
		column-count: 1;
		margin: 0;
		padding: 0;
        text-align: center;
        @include media(">=sm") {
            column-count: 2;
        }
        @include media(">=md") {
            column-count: 3;
        }
		> li {
			display: block;
			page-break-inside: avoid;
			break-inside: avoid;
			margin-bottom: 15px;
			a {
				display: block;
				padding: 30px 65px 30px 30px;
                color: $white; 
                background-color: $main-color-two;
				min-width: rem(100);
				text-decoration: none;
				text-align: left;
				position: relative;
                padding-left: 30px;
                font-size: rem(20);
				font-weight: 700;
				text-decoration: none !important;
                width: 100%;
                position: relative;
                border-radius: 60px;
				&:hover {
                    text-decoration: none;
                    background-color: $main-color-one-alt;
                    &:after {
                        color: $white;
                    }
				}
				&:after {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    top: calc(50% - 30px);
                    right: 20px;
                    border-radius: 30px;
                    font-family: $icon-font;
                    content: '\f054';
                    font-size: 30px;
                    margin-left: 5px;
                    padding-top: 8px;
                    padding-left: 22px;
                    color: $main-color-two;
                    background-color: $main-color-one-alt;
                }
			}
		}
	}
}
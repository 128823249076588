@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400,700');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700&display=swap');


body {
   @include font-setting($primary-font, normal, 400);
    color: $text-color;
	text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
	font-size: 1rem;
}



h1, h2, h3, h4, h5, h6 {
	@include font-setting($header-font, normal, 400);
	color: $text-color;
	font-weight: 700;
}

h1 {
	font-size: rem(36);
	line-height: rem(38);
	margin: 0;
	padding: 0;
}


h2 {
	font-size: rem(34);
}

h3 {
	font-size: rem(26);
}

h4 {
	font-size: rem(20);
}

h5 {
	font-size: rem(18);
}

h6 {
	font-size: rem(16);
}

//Links

a, a:visited {
	color: $color-link;
	text-decoration: none;
	transition: all 0.3s ease;
	&:hover, &:active {
		color:$color-link-hover;
		text-decoration:none;
		outline: 0;
		text-decoration: underline;
	}
	&:focus {
		outline: dotted 2px red;
	}
}

p.dropcap:first-child:first-letter {
	color: #903;
	float: left;
	font-family: Georgia;
	font-size: rem(75);
	line-height: rem(60);
	padding: rem(4) rem(8) rem(3) 0;
}

button  {
	outline: 0;
}

hr {
	margin: rem(40) 0;
    border: 0;
    border-top: 1px dotted $dk-grey;
	&.white {
		border-top: 1px dotted $white;
	}
}

.screen-reader-text {
    left: -9999em;
    position: absolute;
    top: -9999em;
}

.skiplink-text {
	color: $white;
	&:hover {
		color: $white;
	}
}

.sr-only {
	color: $white;
	background-color: $black;
}
.navigation {
	display: none;
	// background-color: $main-color-one;
	@include media(">=md") {
		position: relative;
		display: block;
		background-color: transparent;
		margin-top: 0;
	}
}


.searchContainer {
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    padding: 1px 5px;
    border: solid 3px $grey;
    @include media(">=md") {
        display: inline-flex;
        flex: 1 1 300px;
        width: 300px;
        position: absolute;
        right: rem(160);
        top: rem(-90);
        border-radius: 30px;
    }
    .searchIcon {
            padding: 0.5rem;
            position: absolute;
            top: 0;
            right: 0;
            @include media(">=md") {

            }
        }
    .searchBox {
        height: rem(30);
        border: 0;
        padding: 0.5rem 0.5rem 0.5rem 0;
        flex: 1;
        padding-right: 30px;
        padding-left: 5px;
        width: 100%;
        @include media(">=md") {
            padding-right: 0;
        }
        &:focus {
            outline: none;
        }
    }
}


a.logo-mob {
	display: block;
	width: 148px;
	position: absolute;
	top: 0;
	left: 15px;	
    z-index: 1;
    @include media(">=md") {
        display: none;
    }
}


a {
    &.logo {
        display: none;
        @include media(">md") {
           display: block;
        position: absolute;
        top: rem(-92);
        left: 15px;
        width: 220px; 
        }
        
        img {
            width: 100%;
        }
    }
}



// a.logo-wrapper {
// 	display: none;
// 	@include media(">=md") {
// 		display: block;
// 		top: -56px;
// 		left: 0;
// 		position: absolute;
// 		z-index: 10001;
// 	}
// 	.fifty-logo {
// 		width: rem(116);
// 		margin-top: 0;
// 	}
// 	.text-full {
// 		display: none;
// 		@include media(">=md") {
// 			display: block;
// 			position: absolute;
// 			top: rem(10);
// 			left: rem(130);
// 			width: rem(340);
// 			max-width: none;
// 		}
// 	}
// 	.text-mark {
		
// 		@include media(">=md") {
// 			display: none;
// 		}
// 	}
// }



.main-nav-wrap {
	background: $nav-color-one;
	height: auto;
	position: relative;
	@include media(">=md") {
		position: absolute;
		top: rem(100);
		width: 100%;
    }
    .container {
        position: relative;
        padding-right: 0 !important;
        padding-left: 0 !important;
        @include media(">=md") {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }	
}


ul.main-nav {
	// background-color: $nav-color-one;
	position: relative;
	margin-bottom: 0;
	z-index: 1000;
	@include media(">=md") {
		text-align: left;
		// background-c$nav-color-one;olor: none;
		margin-bottom: 0;
	}
	@include reset-list;
    li {
		position: relative;
		border-bottom: 1px solid $white;
		&:first-child {
			border-top: solid px(1) $white;
		}
		&:last-child {
			border-bottom: none;
		}
		a {
            display: block;
			font-size: 20px;
			padding: 15px 15px;
			height: 62px;
			text-decoration: none;
			background-color: transparent;
			color: $white;
			font-family: $primary-font;
			font-weight: 700;
			transition: none;
			text-transform: uppercase;
            &:hover, &:active {
				color: $subnav-color-hover;
            }
			&:focus {
				// background-color: $black;
				// color: $white;
				outline: none !important;
			}
			@include media(">=md") {
				font-size: rem(18);
				height: rem(44);
				padding: rem(10) rem(14) 0 rem(10);
				color: $white;
				&:focus {
					outline: 2px dotted red;
				}
			}
			&.toggle-sub {
				display: block;
				border-left: 1px solid $white;
				@include media(">=md") {
					display: none;
				}
			}
		}
		/* second level flyout */
		ul {
			display: none;
			padding: 0;
			&.open {
				display: none;
			}
			li {
				a {
					font-size: 16px;
					padding: 20px 10px 10px 20px;
					color: $white;
					// &:before {
					// 	position: relative;
					// 	right: 5px;
					// 	font-family: "FontAwesome";
					// 	font-weight: 300;
					// 	font-size: 12px;
					// 	content: '\f054';
					// }
				}
			}
		}
		/* li @ desktop */
		@include media (">=md") {
			text-align: left;
			display: inline-block;
			border-bottom: 1px solid transparent;
			margin-left: -5px;
			&:hover {
				background-color: darken($nav-color-one, 10%) !important;
				a {
					color: $white;
					transition: none;
				}
				> ul {
					display: block;
					position: absolute;
					top: rem(44);
					width: rem(275);
					left: rem(5);
					z-index: 2000;
					/*second level li width*/
					li {
						width: 100%;
						background-color: darken($main-color-one, 10%) !important;
						a {
							height: auto;
							padding: rem(8) 0 rem(8) rem(10);
							font-size: rem(14);
							&:hover, &:active {
								background-color: $nav-color-hover-one !important;
							}
						}
					}
				}
			}
			&:last-child {
				// background-color: $main-color-two;
				border-bottom: 1px solid transparent;
				@include media(">=md") {
					border-bottom: none;
				}
				a {
					color: $white;
					padding-right: rem(10);
				}
				&:hover {
					> ul {
						display: block;
						position: absolute;
						left: rem(-137);
						z-index: 2000;
					}
				}
			}
			&:first-child {
				border-top: none;
			}
			&.has-sub {
				&:hover {
					// background-color: $nav-color-one !important;
				}	
				> a:after { 
					 position: relative;
					 right: -3px;
					 top: 0px;
					 font-family: $icon-font;
					 font-weight: 700;
					 font-size: rem(14);
					 content: '\f32d';
				}
			}
		}
    }
}

.main-nav {
	li {
		a {
			&:focus {
				border: 2px dotted yellow;
			}
		}
		> ul {
			&.show-menu {
				display: block;
				position: absolute;
				top: rem(44);
				width: rem(275);
				left: rem(5);
				z-index: 2000;
				 li {	
					 background-color: $main-color-one;
					 width: rem(275) !important;
					 a {
						 height: rem(40);
						 font-size: rem(14);
						 padding: 7px 0 0 16px;
						 color: $white;
						 background-color: $main-color-one;
						 &:focus { 
							 border: 2px dotted yellow;
							//  background-color: $black;
						 }
					 }
				}
			}
		}
		&:last-child {
			> ul {
				&.show-menu {
					display: block;
					position: absolute;
					left: rem(-123);
					z-index: 2000;
				}
			}
		}
	}
}
	





/*--------------------------------------------------------------
# Mobile Nav Trigger
--------------------------------------------------------------*/
.mob-nav-wrap {
	// background-color: $white;
	display: block;
	@include media(">=md") {
		display: none;
	}
}
.mob-nav {
	display: block;
	height: 60px;
	@include media(">=md") {
		display: none;
	}
}

a.nav-trigger {
	position: absolute;
	z-index: 3;
	top: 5px;
	right: 5px;
	// border: 1px solid $white;
	height: 50px;
	width: 50px;
	overflow: hidden;
	white-space: nowrap;
	color: transparent;
    text-indent: -9999px;
    &:focus {
        outline: none;
    }
}

// a.search-toggle-mob {
// 	display: block;
// 	position: absolute;
// 	top: 15px;
// 	right: 65px;
// 	text-decoration: none;
// 	color: $main-color-two;
// 	border: 1px solid $main-color-two;
// 	width: 50px;
// 	height: 50px;
// 		&:hover, &:active {
// 			color: $main-color-two;
// 		}
// 		// &:before {
// 		// 	font-family: Material Icons;
// 		// 	content: "\E8B6";
// 		// 	font-size: 30px;
// 		// 	position: absolute;
// 		// 	top: 5px;
// 		// 	left: 10px;
// 		// }
// 		&.search-visible {

// 		}
// 		@include media(">=md") {
// 			display: none;
// 	}
// }
.nav-trigger span, .nav-trigger span:after, .nav-trigger span:before {
	/* this is the hamburger icon */
	position: absolute;
	width: 30px;
	height: 4px;
	background-color: $main-color-one;
}
.nav-trigger span {
	/* middle line of the hamburger icon */
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transition: background-color 0.2s;
	-moz-transition: background-color 0.2s;
	transition: background-color 0.2s;
}
.nav-trigger span:after, .nav-trigger span:before {
	/* top and bottom lines of the hamburger icon */
	content: '';
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.2s;
	-moz-transition: -moz-transform 0.2s;
	transition: transform 0.2s;
}

.nav-trigger span:before {
	top: -2px;
}

.nav-trigger span:after {
	top: 2px;
}

.nav-trigger span:before {
	// -webkit-transform: translateY(-6px);
	// -moz-transform: translateY(-6px);
	// -ms-transform: translateY(-6px);
	// -o-transform: translateY(-6px);
	transform: translateY(-6px);
}

.nav-trigger span:after {
	// -webkit-transform: translateY(6px);
	// -moz-transform: translateY(6px);
	// -ms-transform: translateY(6px);
	// -o-transform: translateY(6px);
	transform: translateY(6px);
}
.no-touch .nav-trigger:hover ~ .stretchy-nav-bg {
 	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}
.nav-is-visible .nav-trigger span {
 	background-color: transparent;
}
.nav-is-visible .nav-trigger span:before {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 0;
}
.nav-is-visible .nav-trigger span:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 0;
}



/*--------------------------------------------------------------
# Subnav
--------------------------------------------------------------*/

.subnav {
	background: $main-color-one;
	list-style: none;
	padding: px(10);
/*	@include media(">=md") {
		margin: 0 -40px;
	}*/
	li {
		border-bottom: 1px solid $white;
		&:last-child {
			border-bottom: none;
		}
	}
	a, a:visited {
		color: $white;
		font-size: px(14);
		display: block;
		padding: px(10) 0 px(10) px(20);
		text-decoration: none;
		&:hover {
			color: #ccc;
		}
		@include media(">=md", "<=lg") {
			font-size: px(12);
		}
		@include media(">lg") {
			font-size: px(18);
		}
	}
}

/*--------------------------------------------------------------
# Toggle Sub
--------------------------------------------------------------*/
ul {
	li {
		.toggle-sub {
			background-color: #334b94;
			color: $grey;
			cursor: pointer;
			display: inline-block;
			font-size: inherit;
			line-height: inherit;
			padding: 18px 21px 17px 21px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 100;
			@include media(">=md") {
				display: none;
			}
			// &:hover {
			// 	background-color: darken($main-color-one, 10%);
			// }
			&:before {
				font-family: $icon-font;
				font-weight: 400;
				content: '\f078';
				color: $white;
			}
			&.menu-visible {
				&:before {
					font-family: $icon-font;
					font-weight: 400;
					content: '\f077';
				}
			}
		}
		ul {
			li {
				.toggle-sub {
					background-color: #3f5bb0;
				}
				ul {
					li {
						.toggle-sub {
							background-color: #5274d9;
						}
					}
				}
			}
		}
	}
}

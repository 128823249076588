.alert {
    margin-bottom: 0;
    border-radius: 0;
    background-color: $main-color-three;
    color: $black;
    .container {
        position: relative;
        display: flex;
        div {
            i {
                &.fas {
                    font-size: 26px;
                }
            }
            &:first-child {
                width: 40px;
                flex: 0 0 40px;
            }
            &:nth-child(2) {
                flex-grow: 1;
                padding-left: 20px;
                display: flex;
                strong {
                    text-transform: uppercase;
                    margin-right: 10px;
                }
            }
            &:nth-child(3) {
                flex: 1;
            }
        }
    }
    &.policy {
        background-color: $xlt-grey;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1000;
        .button {
            padding: 3px 10px;
            display: none;
        }
    }
}

.alert-dismissible {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.horizontal-tabs {
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    .nav {
        display: block;
        @include media(">=sm") {
            display: flex;
        }
        &.nav-tabs {
            display: block;
            position: relative;
            // border-bottom: 1px solid $main-color-one;
            background-color: $white;
            @include media(">=sm") {
                display: flex;
            }
            .nav-item {
                flex: 1;
                // margin-bottom: -1px;
                @include media(">=sm") {
                    border-right: 5px solid $white;
                    &:last-child {
                        border-right: none;
                    }
                }
                .nav-link {
                    border: none;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    font-weight: 700;
                    text-decoration: none !important;
                    background-color: $main-color-two;
                    color: $white;
                    text-align: center;
                    padding: 20px 0;
                    border-bottom: 5px solid $white;
                    &:after {
                        font-family: $icon-font;
                        margin-left: 10px;
                        content: "\f055";
                      }
                    &.active {
                        color: $main-color-one;
                        background-color: $white;
                        color: $main-color-one;
                        &:hover {
                            // border-bottom-color: $white;
                            background-color: $white;
                            color: $main-color-one;
                        }
                    }
                    &:hover {
                        // border-color: transparent;
                        background-color: $main-color-one;
                        color: $white;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }	 
        }
    }  
}

.vertical-tabs {
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    border: solid 5px $white;
    .nav-tabs {
        display: flex;
        position: relative;
        border-bottom: 1px solid $main-color-one;
        background-color: $white;
        height: 100%;
        border-bottom: none;
        background-color: $grey;
        .nav-link {
            border: 1px solid transparent;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-weight: 700;
            text-decoration: none !important;
            background-color: $main-color-two;
            color: $white;
            text-align: center;
            padding: 20px 0;
            &:after {
                font-family: $icon-font;
                margin-left: 10px;
                content: "\f055";
              }
            &.active {
                color: $main-color-one;
                background-color: $white;
                color: $main-color-one;
                &:hover {
                    border-bottom-color: $white;
                    background-color: $white;
                    color: $main-color-one;
                }
            }
            &:hover {
                border-color: transparent;
                background-color: $main-color-one;
                color: $white;
            }
            &:focus {
                outline: none;
            }
        }
        .nav-item {
            margin-right: 0;
            margin-bottom: 0;
            border-bottom: 5px solid $white;
            // flex: 0;
            &:last-child {
                margin-right: 0;
            }
            .nav-link {
                &.active {
                    border: none;
                }
            }
        }	
    }
}



.tab-content {
	position: relative;
	text-align: left;
	padding: rem(20);
	// border-right: solid 1px $main-color-one;
	// border-left: solid 1px $main-color-one;
    // border-bottom: solid 1px $main-color-one;
    &.vertical {
        border-right: none;
        border-left: none;
        border-bottom: none;
    }
}


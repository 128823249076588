footer {
    padding: 60px 0;
    background-color: $main-color-one;
    display: flex;
    .logo {
        // display: flex;
        // align-items: center;
    }
    .address {
        color: $white;
        font-size: rem(20);
        h2 {
            color: $white;
            font-size: rem(24);
            border-bottom: 1px white solid;
            margin-top: 20px;
            @include media(">=sm") {
                margin-top: 0;
            }
        }
    }
    .links {
        h2 {
            color: $white;
            font-size: rem(24);
            border-bottom: 1px white solid;
            margin-top: 20px;
            @include media(">=sm") {
                margin-top: 0;
            }
        }
        ul {
            column-count: 2;
            margin: 0;
            padding: 0;
           li {
                display: block;
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
                a {
                    display: block;
                    color: $white;
                    padding-left: 5px;
                    border: 2px solid transparent;
                    &:hover {
                        border-color: $white;
                        text-decoration: none;
                        background-color: $white;
                        color: $main-color-one;
                    }
                }
           }
        }
    }
}
.pathways {
    max-width: rem(1400);
    margin: 0 auto;
    .links {
        margin-bottom: 60px;
        align-items: center;
        @include media(">=md") {
            display: flex;
        }
        .image-wrapper {
            display: none;
            @include media(">=md") {
                display: block;
                width: 300px;
                img {
                    width: 100%;
                }
            } 
        }
        .details {
            margin-bottom: rem(40);
            padding: 0 15px;
            @include media(">=md") {
                flex-grow: 1;
                padding: 0 0 0 30px; 
                width: 30%;
                margin-bottom: 0;
            }
            .title {
                font-size: rem(34);
                // line-height: rem(10);
                font-weight: 700;
                // text-align: ce   nter;
                @include media(">=md") {
                    font-size: rem(46);
                    line-height: rem(30);
                }
                span {
                    font-size: rem(34);
                    color: $main-color-one;
                    text-transform: uppercase;
                    @include media(">=md") {
                        display: block;
                        font-size: rem(62);
                        line-height: rem(62);
                    }
                }
            }
            .subtitle {
                margin-top: 30px;
                font-size: rem(22);
                line-height: rem(22);
                text-align: left;
            }
        }
        .icon-links {
            flex-grow: 1;
            background-color: $main-color-one;
            position: relative;
            overflow: hidden;
            padding: 20px 0;
            @include media(">=md") {
                padding: 0 0 0 100px;
            }
            &:before {
                display: none;
                @include media(">=md") {
                    display: block;
                    content: '';
                    background-image: url(../images/pathways-arrow.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: contain;
                    width: 100%;
                    height: 101%;
                    position: absolute;
                    left: -1px;
                    // height: 100%;
                    // width: 100px;
                }    
            }
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                column-count: 1;
                @include media(">=sm") {
                    column-count: 2;
                }
                @include media(">md") {
                    column-count: 1;
                }
                li {
                    display: block;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                    margin: 0;
                    &:first-child {
                        margin-top: 20px;
                    }
                    &:last-child {
                        margin-bottom: 20px;
                    }
                    @include media(">=sm") {
                        &:first-child {
                            margin-top: 0;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    @include media(">md") {
                        &:first-child {
                            margin-top: 20px;
                        }
                        &:last-child {
                            margin-bottom: 20px;
                        }
                    } 
                    a {
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 5px 10px;
                        margin-right: 10px;
                        // border-radius: 20px;
                        .image-wrapper {
                            display: block;
                            width: 40px; 
                            img {
                                width: 100%;
                            }
                        }
                        .text {
                            padding-left: 10px;
                            text-transform: uppercase;
                            color: $white;
                            font-size: rem(18);
                        }
                        &:hover {
                            background-color: $white;
                            text-decoration: none;
                            .text {
                                color: $main-color-one;
                            }
                        }
                    }
                }
            }  
        }
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        a {
            display: flex !important;
            flex-grow: 1;
            border: solid 5px $main-color-two;
            display: inline-block;
            color: $white;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-transform: uppercase;
            background-color: $main-color-two;
            height: auto;
            margin: 5px;
            @include media(">=md") {
                width: 30%;
            }
            &:before {
                position: absolute;
                display: block;
                top: calc(50% - 100px);
                // transform: translateX(110px) translateX(-100%);
                left: -1292px;
                content: url(../images/bg-arrow-white.svg);
                width: 1400px;
                z-index: -1;
                transition: all .5s ease;
            }
            &:hover,  &:active, &:focus {
                color: $main-color-one;
                text-decoration: none;
                border-color: $main-color-two;
                &:before {
                    // transform: translateX(-90%) translateX(60%);
                    left: 0px;
                }
                .text {
                    .inner {
                        .title, .subtitle {
                            color: $main-color-two;
                        }
                    }
                }
            }
            &:focus {
                outline: 2px dashed $focus-color;
            }
            // &:nth-child(1), &:nth-child(2) {
            //     @include media(">=md") {
            //         width: 45%;
            //         .media {
            //             width:150px;
            //         }
            //         &:before {
            //             transform: translateX(130px) translateX(-97.5%);
            //         }
            //         &:hover {
            //             &:before {
            //                 transform: translateX(-100%) translateX(90%);
            //             }
            //         } 
            //     } 
            // }
            .media {
                display: block;
                width: 100px;
                img {
                    width: 100%;
                }
            }
            .text {
                display: block;
                display: flex;
                align-items: center;
                color: $white;
                margin-left: 20px;
                width: 210px;
                .inner {
                    display: block;
                    .title {
                        display: block;
                        text-transform: uppercase;
                        color: $white;
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 700;
                        transition: all .5s ease;
                        @include media(">=xs") {
                            line-height: 30px;
                            line-height: 30px
                        }
                    }
                    .subtitle {
                        display: block;
                        font-size: 18px;
                        line-height: 18px;
                        transition: all .5s ease;
                        @include media(">=xs") {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}
.lp-hero {
    img {
        width: 100%;
    }
}







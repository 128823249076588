.image-teaser {
    position: relative;
    color: #fff;
    .image-teaser-wrapper {
        position: relative;
        .image-teaser-inner {
            display: block;
            @include media(">=sm") {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
                // max-width: 85rem;
                margin: 0 auto;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-flow: row wrap;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }
            .image-teaser-body {
                -webkit-box-flex: 0;
                position: relative;
                z-index: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                flex-flow: column nowrap;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                width: 100%;
                padding: 20px 20px 60px 20px;
                // background-color: $main-color-three;
                @include media(">=sm") {
                    min-height: 30rem;
                    flex: 0 1 45%;;
                    padding-right: 5%;
                    padding-left: 5%;
                    padding-top: 40px;
                    padding-bottom: 40px;
                }
                h1, h2 {
                    color: $white;
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 20px;
                    color: $text-color;
                }
                .image-teaser-summary {
                    // border-top: 2px solid $white;
                    // border-bottom: 2px solid $white;
                    color: $text-color;
                    padding: rem(10) 0;
                    margin-bottom: rem(20);
                    text-align: left;
                    font-weight: 700;
                    @include media(">=sm") {
                        font-size: rem(22);
                        line-height: rem(24);
                    }
                }
                .image-teaser-button {
                    display: block;
                    a {
                        display: inline-block !important;
                        // position: absolute;
                        // bottom: 0;
                        // right: 0;
                        border: 2px solid $main-color-one;
                        padding: 10px 20px;
                        color: $main-color-one;
                        font-size: rem(20);
                        font-weight: 700;
                        margin-top: 20px;
                        width: auto;
                        text-align: center;
                        background-color: transparent;
                        text-decoration: none !important;
                        text-transform: uppercase;
                        border-radius: rem(60);
                        &:after {
                            font-family: $icon-font;
                            content: '\f054';
                            margin-left: 5px;
                        }
                        &:hover {
                            color: $white;
                            background-color: $main-color-one;
                            text-decoration: none;
                        }
                    }
                }  
            }
            .image-teaser-media {
                width: 100%;
                position: relative;
                z-index: 1;
                @include media(">=sm") {
                    margin-top: 0;
                    position: absolute;
                    content: '';
                    display: block;
                    margin: 0;
                    z-index: 0;
                    max-width: 50%;
                    top: 0;
                    bottom: 0;
                    left: auto;
                    right: 0;
                    overflow: hidden;
                    z-index: 2;
                    &:after {
                        display: block;
                        content: '';
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-image: url(../images/bg-teaser.svg);
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }   
                img {
                    display: inline-block;
                    max-width: 100%;
                    vertical-align: middle;
                    border-style: none;
                    @include media(">=sm") {
                        position: absolute;
                        max-height: 110%;
                        max-width: none;
                        width: auto;
                        transform: translate(-50%, -50%);
                        height: auto;
                        min-height: 100%;
                        right: auto;
                        left: 50%;
                        top: 50%;
                        min-width: 100%;
                    }
                }
            }
        }
    }
    &.flip {
        .image-teaser-wrapper {
            &:before {
                margin: 0 -750px 0 auto;
            }
            .image-teaser-inner {
                .image-teaser-body {
                    order: 2;
                    padding-right: 0;
                    @include media(">=sm") {
                        min-height: 30rem;
                        flex: 0 1 45%;
                        padding-right: 5%;
                        padding-left: 5%;
                        padding-top: 40px;
                        padding-bottom: 40px;
                    }
                }
                .image-teaser-media {
                    left: 0;
                    right: auto;
                    @include media(">=sm") {
                        &:after {
                            background-image: url(../images/bg-teaser-flip.svg);
                            background-position: right center;
                        }
                    }
                }
            }
        }
    }
    &.alt {
        .image-teaser-wrapper {
            // background-color: $main-color-three;
            .image-teaser-body {
                background-color: $main-color-one;
            }
        }
    }
    &.alt-two {
        .image-teaser-wrapper {
            // background-color: $main-color-three;
            .image-teaser-body {
                background-color: $main-color-two;
                h2 {
                    color: $white;
                }
                .image-teaser-summary {
                    color: $white;
                }
                a {
                    &.image-teaser-button {
                        color: $white;
                        background-color: $main-color-one-alt;
                        &:hover {
                            color: $main-color-one-alt;
                            background-color: $white;
                            border-color: $main-color-one-alt;
                        }
                    }
                }  
            }
        }
    }
}



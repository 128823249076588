a, button {
    &.button {
        display: inline !important;
        border: 2px solid $main-color-one;
        padding: 10px 20px;
        color: $main-color-one !important;
        font-size: rem(20);
        font-weight: 700;
        margin-top: 20px;
        width: auto;
        text-align: center;
        background-color: transparent;
        text-decoration: none !important;
        text-transform: uppercase;
        border-radius: rem(60);
        margin: 0;
        &:after {
            font-family: $icon-font;
            content: '\f054';
            margin-left: 5px;
        }
        &:hover {
            color: $white !important;
            background-color: $main-color-one;
            text-decoration: none;
        }
    }
}


/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/


.header {
	position: relative;
	height: auto;
	z-index: 1000;
	box-shadow: none;
	width: 100%;
	border-bottom: 3px solid $main-color-one;
	@include media(">=md") {
		height: rem(145);
        transition: all 0.3s ease;
        border-bottom: none;
	}
	.mob {
		display: block;
		padding-top: 20px;
		width: 68px;
		height: 50px;
		float: right;
		@include media(">=md") {
			display: none;
		}
		.fa {
			font-size: 30px;
			color: $nav-color-two;
		}
	}
}	



.hm-social {
    ul {
        @include reset-list;
        list-style: none;
        padding: 0;
        text-align: center;
        margin-bottom: 0;
        li {
            @include reset-list;
			display: inline-block;
            position: relative;
            margin: 10px;
            @include media(">=sm") {
                margin: 10px 2%;
            }
            a {
                display: inline-block;
                border: solid 5px;
                padding: 10px 0;
                text-align: center;
                font-size: 20px;
                width: 60px;
                span {
                    font-size: 18px;
                    font-weight: 700;
                }
                &:hover {
                    background-color: $main-color-two;
                    color: $white;
                    text-decoration: none;
                    border-color: $main-color-two;
                }
            }
        }
    }
}
.section-intro {
    margin-bottom: rem(60);
    h2 {
        text-align: center;
    }
    .subhead {
        text-align: center;
        font-size: rem(24);
        position: relative;
        &:after {
            content: '';
            background-color: $main-color-one;
            width: 200px;
            height: 10px;
            position: absolute;
            bottom: -30px;
            margin: 0 auto;
            right: 0;
            left: 0;
        }
    }
}
.carousel-events-list {
    padding: 60px 30px;
    .stat {
        padding: 0 30px;
        text-align: center;
        color: $main-color-two;
        .head {
            font-size: rem(100);
            line-height: rem(102);
            font-weight: 700;
        }
        .subhead {
            font-size: rem(30);
            line-height: rem(32);
            font-weight: 700;
        }
        .detail {

        }
    }
    &.hm {
        padding: 60px 30px 120px 30px;
    }
}

.site-search {	
	display: none;
	background-color: $main-color-two;
	padding: 10px;
	color: $main-color-two;
	margin: 0;
	@include media(">=md") {
		margin: 0;
	}
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

.orangetext {
    color: $main-color-one;
}

.headerSearchContainer {
    display: none;
    background: #0c2340
}

.headerSearchContainer input {
    left: 5%;
    padding: .8em 0;
    margin: .8em 0;
    text-indent: 15px;
    width: 90%;
    border: 1px solid #999;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    background: #fff
}

.headerSearchContainer input .wrapper:after {
    float: right;
    font-family: FontAwesome;
    color: #f15a22;
    font-size: 1.25em;
    top: 22px;
    right: 7%;
    position: relative;
    content: "\f002"
}

.headerSearchContainer input .wrapper input[type=text] {
    position: relative
}

#headerSearchCTA {
    color: #f15a22
}

// .showSearch {
//     display: block
// }

// .hideSearch {
//     display: none
// }


// // .site-search {	
// 	display: none;
// 	background-color: $white;
// 	padding: 10px;
// 	color: $main-color-two;
// 	margin: 0;
// 	@include media(">=md") {
// 		margin: 0;
// 	}
// 	.search-fields {
// 		position: relative;
// 		display: block;
// 		margin-left: auto;
// 		margin-right: auto;
// 		&:before {
// 			display: block;
// 			color: #fff;
// 			font-family: Material Icons;
// 			content: "\E8B6";
// 			font-size: 24px;
// 			left: 8px;
// 			position: absolute;
// 			top: 2px;
// 			z-index: 999;
// 		}
// 	}
// 	input[type=search] {
// 		background-color: transparent;
// 		border: solid 1px $white;
// 		border-radius: 0;
// 		color: $white;
// 		font-weight: 300;
// 		padding: px(10) px(10) px(10) px(36);
// 		width: 100%;
// 		color: $white;
// 		display: inline-block;
// 		font-size: px(20);
// 		&:focus {
//     		color: #fff;
// 			outline: 0;
// 		}
// 		&::-moz-placeholder, :-moz-placeholder, &::-webkit-input-placeholder, &:-webkit-input-placeholder, &:-ms-placeholder {
//     		color: #000 !important;
// 		}
// 	}
// }

// a.search-visible {
// 	color: $main-color-one;
// }



// .search-item-title {
// 	font-size: rem(22);
// 	font-weight: 700;
// }

// .listHeader {
// 	input[type=button] {
// 		border: solid 1px $main-color-three;
// 		background-color: $white;
// 		color: $main-color-one;
// 	}
// }



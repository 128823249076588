.cta {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    background-color: $main-color-one;
    padding: 20px;
    @include media(">=sm") {
        padding: 60px;
    }
    &:after {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($main-color-one, .75);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .inner {
        position: relative;
        color: $white;
        // background-color: $main-color-one-alt;
        padding: 20px;
        z-index: 1;
        text-align: center;
        // @include media(">=sm") {
        //     padding: 60px;
        // }
        .title {
            text-align: center;
            font-size: rem(30);
            line-height: rem(32);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
            @include media(">=sm") {
                font-size: rem(40);
                line-height: rem(42);
            }
            @include media(">=lg") {
                font-size: rem(60);
                line-height: rem(62);
            }
        }
        .subtitle {
            text-align: center;
            font-size: rem(20);
            line-height: rem(22);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
            @include media(">=sm") {
                font-size: rem(30);
                line-height: rem(32);
            }
        }
        .details {
            font-size: rem(22);       
            line-height: rem(24);     
            text-align: center;
            @include media(">=sm") {
                font-size: rem(26);       
                line-height: rem(28); 
            }
        }
        a {
            &.more {
                display: inline-block;
                border: 2px solid $white;
                padding: 20px 30px;
                color: $white;
                font-size: rem(20);
                font-weight: 700;
                width: auto;
                text-align: center;
                background-color: transparent;
                text-decoration: none !important;
                text-transform: uppercase;
                margin-top: 20px;
                border-radius: 60px;
                margin-top: 40px;
                &:after {
                    font-family: $icon-font;
                    content: '\f054';
                    margin-left: 5px;
                }
                &:hover {
                    color: $main-color-one;
                    background-color: $white;
                    text-decoration: none;
                    border-color: $main-color-one;
                }
                // @include media(">=sm") {
                //     margin-top: 0;
                //     display: inline-block;
                //     position: absolute;
                //     bottom: -20px;
                //     right: -20px;
                // }
            }
        }
    }
}
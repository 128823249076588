.icon-slab {
    @include media(">=sm") {
        display: flex;
    }
    .item {
        flex: 0 1 33%;
        position: relative;
        text-align: center;
        padding: 0 20px 90px 20px;
        border: 5px solid $main-color-two;
        margin-bottom: 20px;
        @include media(">=sm") {
            border: none;
            margin-bottom: 0;
        }
        .icon {
            font-size: 80px;
            color: $main-color-two;
            @include media(">=sm") {
                font-size: 120px;
            }
        }
        .detail {
            font-size: rem(18);
            text-align: center;
        }
        .more-button {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-left: -20px;
            a {
                display: inline-block;
                border: 2px solid transparent;
                padding: 20px;
                color: $white;
                font-size: rem(20);
                font-weight: 700;
                width: auto;
                text-align: center;
                background-color: $main-color-two;
                text-decoration: none !important;
                text-transform: uppercase;
                &:after {
                    font-family: $icon-font;
                    content: '\f054';
                    margin-left: 5px;
                }
                &:hover {
                    color: $main-color-one;
                    background-color: $white;
                    text-decoration: none;
                    border-color: $main-color-one-alt;
                }
            }
        }
    }
}
.top-nav-wrap {
	position: relative;
	z-index: 100;
	@include media(">=md") {
		// height: 44px;
        // position: absolute;
        // top: rem(60);
		width: 100%;
    }
    .container {
        position: relative;
        padding-right: 0 !important;
        padding-left: 0 !important;
        @include media(">=md") {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }
    a {
        &.apply-bttn {
            display: block;
            border: 3px solid $main-color-two;
            background-color: $main-color-two;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            padding: 20px;
            @include media(">=md") {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(120);
                height: rem(100);
                position: absolute;
                right: 15px;
                top: 0;
            }
            &:hover {
                text-decoration: none;
                background-color: $white;
                color: $main-color-two;
                border-color: $main-color-two;
            }
        }
    }
	ul.top-nav {
		@include reset-list;
		list-style: none;
		padding: 0;
        text-align: left;
        margin-bottom: 0;
		// margin: rem(50) rem(200) 0 0;
		@include media(">=md") {
            position: absolute;
            top: rem(55);
            right: rem(160);
			text-align: right;
			padding: rem(7) 0 0 0;
		}
		li {
			@include reset-list;
			display: block;
            position: relative;
            border-bottom: solid 1px $grey;
            &:last-child {
                border-bottom: none;
            }
			@include media (">=md") {
                display: inline-block;
                border-bottom: none;
                &:after {
                    content: ' | ';
                }
                &:last-child {
                    &:after {
                        content: '';
                    }
                }
			}
			// &.search-toggle {
			// 	display: none;
			// 	@include media(">=md") {
			// 		display: inline;
			// 	}
			// 	a {
			// 		position: relative;
			// 		padding: rem(5) rem(15);
			// 		&:before {
			// 			font-family: $icon-font;
			// 			content: "\f002";
			// 			font-size: 16px;
			// 			position: absolute;
			// 			top: 2px;
			// 			left: 8px;
			// 		}
			// 	}
			// }
			a {
				padding: 15px;
				text-transform: none;
				color: $dk-grey;
                font-size: 20px;
                font-weight: 700;   
				display: block;
                height: auto;
				&:hover {
					text-decoration: none;
				}
				@include media(">=md") {
					display: inline;
					height: auto;
					font-size: rem(16);
					border: solid 1px transparent;
					padding: rem(5);
                    font-weight: 700;
					&:hover {
						text-decoration: none;
						border-color: $dk-grey;
					}
					&:focus {
						background-color: $black;
						color: $white;
					}
					i {
						font-weight: 300;
					}
				}
			}
		}
		@include media(">=md") {
			>li+li:before {
				// padding: 0 5px;
				// color: $white;
				// content: " | ";
			}
		}	
	}
}
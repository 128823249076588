.slab {
    position: relative;
    @import 'components/slabs/pathways';
    @import 'components/slabs/cta';
    @import 'components/slabs/hm_events';
    @import 'components/slabs/icon';
    @import 'components/slabs/intros';
    @import 'components/slabs/link_grid';
    @import 'components/slabs/hm_social';
    @import 'components/slabs/teasers';
}
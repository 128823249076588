&.hm-events {
    padding:  20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $main-color-one;
    @include media(">=sm") {
        padding:  60px 60px 80px 60px;
    }
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($main-color-one, .75);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    h2 {
        position: relative;
        color: $white;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
}

&.hm-events-list {
    padding:  20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 140px center;
    background-color: $hilite-color-one;
    @include media(">=42.5rem") {
        background-size: contain;
    }
    // @include media(">=sm") {
    //     padding:  60px;
    // }
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($hilite-color-one, .75);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    h2 {
        position: relative;
        // color: $white;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
}






.carousel-events {
    position: relative;
    background-color: $white;
    padding: 60px;
	&.slick-slider {
		margin: 0 -10px !important;
	}
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		height: auto;
	}
	.slick-prev:before, .slick-next:before {
        color: $black;
        font-weight: 400;
	}
	.slick-prev {
		top: 40px;
		left: 10px;
		color: $black;
	}
	.slick-next {
		top: 40px;
		right: 10px;
		color: $black;
	}
	.slick-next:before, .slick-prev:before {
		font-size: rem(30);
        font-weight: 700;
    }
    .slick-dots {
        margin-left: -60px;
        bottom: 5px;
    }
    .item {
        margin: 10px;
        a {
            display: block;
            // border: 2px solid $main-color-one;
            color: $main-color-one;
            height: 100%;
            .media {
                display: block;
                overflow: hidden;
				height: auto;
				.inner {
					display: block;
					height: 200px;
					background-size: cover;
					background-position: center center;
					transition: all .5s ease-in-out;
                    transform: scale(1);
                    @include media(">=xs" , "<=sm") {
                        height: 420px;
                    }
				}
                img {
                    max-width: 100%;
                    transition: all .5s ease-in-out;
                    transform: scale(1);
                }
            }
            .editorial {
                display: block;
                padding: 10px 0 0 0;
                .date {
					display: inline;
					font-size: rem(16);
					font-family: $condensed-font;
					font-weight: 700;
					text-transform: uppercase;
					// &:after {
					// 	content: ' | ';
					// 	font-size: rem(16);
					// }
				}
				.category {
					display: inline;
					text-transform: uppercase;
					ul {
						@include reset-list;
						margin: 0;
						padding: 0;
						display: inline-block;
						li {
							display: inline-block;
							text-align: left;
							font-size: rem(12);
							font-family: $condensed-font;
							font-weight: 700;
							border-bottom: none;
							padding-bottom: 0;
							margin-bottom: 0;
							&:after {
								content:', '
							}
							&:last-child {
								&:after {
									content: '';
								}
							}
						}
					}
				}
                .title {
                    display: block;
                    font-weight: 700;
                    font-size: rem(18);
					margin-bottom: 10px;
					text-transform: uppercase;
					font-family: $condensed-font;
                }
                .detail {
                    display: block;
                    font-size: rem(14);
					line-height: rem(16);
					@include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                }
            }
            &:hover {
				// color: $main-color-two;
				color: #002d6a; /* Modify */
                text-decoration: none;
                // border: 2px solid $main-color-two;
                .media {
                    .inner {
                        transition: all .5s ease-in-out;
                        transform: scale(1.15);
                    }
				}
				.editorial {
					.title {
						border-bottom: 2px solid $main-color-two; /* Add */
						margin-bottom: 8px; /* Add */
					}
				}
			}
			&:focus {
				outline: dotted 2px $main-color-one;
			}
        }  
    }   
}


.carousel-event-list {
    margin: 0;
    border-right: none;
    @include media(">=xs") {
        margin: 0 20px;
    }
    @include media(">=42.5rem") {
        border-right: 5px solid $hilite-color-one;
        margin: 0 40px;
    }
    &:before {
        display: block;
        content: '';
        width: 7px;
        height: 100%;
        background-color: $hilite-color-one;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .slick-prev, .slick-next {
		color: $black;
	}
    .event {
        padding: 2px;
        position: relative;
        @include media(">=42.5rem") {
            padding: 20px;
        }
        border-left: 5px solid $black;
        a {
            display: block;
            width: 100%;
            @include media(">=xs") {
                display: flex;
            }
            .date {
                display: flex;
                flex: 0 0 5rem;
                width: rem(80);
                height: rem(80);
                align-items: center;
                border: 3px solid $black;
                padding: 10px;
                margin: 0 auto 20px auto    ;
                @include media(">=42.5rem") {
                    margin: auto;
                }
                .inner {
                    display: block;
                    width: 100%;
                    .month {
                        display: block;
                        text-align: center;
                        color: $black;
                        font-size: 18px;
                        line-height: 10px;
                        // line-height: rem(18);
                        font-weight: 700;  
                    } 
                    .day {
                        display: block;
                        width: 100%;
                        text-align: center;
                        color: $black;
                        font-size: 48px;
                        line-height: 30px;
                        // line-height: rem(20);
                        font-weight: 700;  
                    }
                }            
            }
            .details {
                display: block;
                @include media(">xs") {
                    padding-left: 20px;
                }
                .title {
                    display: block;
                    text-transform: uppercase;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 700;
                    color: $black;
                    margin-bottom: 10px;
                    // @include multiLineEllipsis($lineHeight: 20px, $lineCount: 2, $bgColor: $hilite-color-one);  
                }
                .description {
                    display: block;
                    color: $black;
                    font-size: 14px;
                    font-weight: 700;
                    margin-top: -8px;
                    // @include multiLineEllipsis($lineHeight: 16px, $lineCount: 2, $bgColor: $hilite-color-one);  

                }
                .time {
                    display: block;
                    color: $black;
                    font-style: italic;
                    font-weight: 700;
                }
            }
            &:hover {
                text-decoration: none;
                .date {
                    background-color: $main-color-one;
                    .inner {
                        .month {
                            color: $white; 
                        } 
                        .day {
                            color: $white;
                        }
                    }
                }              
                .details {
                    .title, .description, .time {
                        color: $main-color-one;
                    }
                }
            }
        }
    }
}


// .all-events {
//     // border-top: 1px dotted $black;
//     position: relative;
//     margin-top: 20px;
//     padding-top: 20px;
//     text-align: center;
//     a {
//         display: inline-block;
//         border: 2px solid $black;
//         padding: 10px 20px;
//         color: $black;
//         font-size: rem(20);
//         font-weight: 700;
//         width: auto;
//         text-align: center;
//         background-color: transparent;
//         text-decoration: none!important;
//         text-transform: uppercase;
//         border-radius: rem(60);
//         &:after {
//             font-family: $icon-font;
//             content: '\f054';
//             margin-left: 5px;
//         }
//         &:hover {
//             color: $main-color-one-alt;
//             background-color: $white;
//             text-decoration: none;
//             border-color: $main-color-one-alt;
//         }
//     }
// }

a {
    &.all-events, &.all-news {
        position: relative;
        display: block;
        border: 2px solid $white;
        padding: 10px 20px;
        color: $white;
        font-size: rem(20);
        font-weight: 700;
        width: rem(220);
        text-align: center;
        background-color: transparent;
        text-decoration: none!important;
        text-transform: uppercase;
        border-radius: rem(60);
        margin: 40px auto 0 auto;
        &:after {
            font-family: $icon-font;
            content: '\f054';
            margin-left: 5px;
        }
        &:hover {
            color: $main-color-one-alt;
            background-color: $white;
            text-decoration: none;
            border-color: $main-color-one-alt;
        }
    }
    &.all-events {
        border-color: $black;
        color: $black;
    }
}